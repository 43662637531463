import { useRouteLoaderData } from "@remix-run/react"

/** Returns an object with hooks for retrieving language information from the route loader. */
export const createLangHooks = <T extends (...args: any[]) => any>() =>
  ({
    useLang(): Awaited<ReturnType<T>>["lang"] {
      return Object.assign(
        {
          name: "en",
          dir: "ltr",
          terms: {},
        },
        useRouteLoaderData<T>?.("root")?.lang,
      )
    },
  }) as const
